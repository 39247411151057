import * as React from 'react';
import { Redirect, Route } from 'react-router-dom';

export interface PrivateRouteProps {
    isLoggedIn: Boolean;
    path: string;
    element: React.ReactElement;
}


class PrivateRoute extends React.Component<PrivateRouteProps, {}> {
    constructor(props: PrivateRouteProps) {
        super(props);
    }
    render() {
        if (this.props.isLoggedIn) {
            // return <Route path={this.props.path} component={this.props.component} />
            return (
                <Route path={this.props.path}>
                    {this.props.element}
                </Route>
            )
        }
        else {
            return <Redirect to="/SignIn" />;
        }
    }
}

export default PrivateRoute;