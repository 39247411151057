import * as React from 'react';
import { Redirect } from 'react-router-dom';

export interface SignInProps {
    signIn: Function;
    isLoggedIn: Boolean;
}

export interface SignInState {

}

class SignIn extends React.Component<SignInProps, SignInState> {
    state = {}
    render() {
        if (this.props.isLoggedIn) {
            return (
                <Redirect to="/" />
            );
        }
        else {
            return (
                <div className="container">
                    <div className="text-center">
                        <h1 className="h3 mb-3">Please sign in</h1>
                        <form id="signInForm">
                            {/* <label for="inputEmail">Email address</label> */}
                            <input type="email" id="inputEmail" placeholder="Email address" className="form-control" />
                            <input type="password" id="inputPassword" placeholder="Password" className="form-control" />
                            <button className="btn btn-lg btn-primary btn-block" onClick={(e) => this.handleSignIn(e)}>Sign in</button>
                        </form>
                    </div>
                </div>
            );
        }

    }

    private handleSignIn(e: any) {
        e.preventDefault();
        // let email: HTMLInputElement = (<HTMLInputElement>document.getElementById("inputEmail"));
        let email: any = document.getElementById("inputEmail");
        email = email.value;

        let password: any = document.getElementById("inputPassword");
        password = password.value;

        this.props.signIn(email, password);
    }
}

export default SignIn;