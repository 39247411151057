import React from 'react';
import logo from './logo.svg';
// import './App.css';

import './style/App.scss';
import '../node_modules/bootstrap/scss/bootstrap.scss';

import '../node_modules/jquery/src/jquery';
import '../node_modules/bootstrap/js/src/collapse.js';

import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import League from './components/League';
import SignIn from './components/SignIn';
import Home from './components/Home';
import NavigationBar from './components/NavigationBar';
import MyTeam from './components/MyTeam';
import PrivateRoute from './components/PrivateRoute';
import AllPlayers from './components/AllPlayers';

//Interfaces
import { IPlayer } from './types/IPlayer';
import SignedOut from './components/SignedOut';
import { IFootballPlayer } from './types/IFootballPlayer';
import { IUnderstat } from './types/IUnderstat';

export interface AppProps {
  baseEndpointUrl: String;
}

export interface AppState {
  signedIn: Boolean;
  checkedLogin: Boolean;
  player: IPlayer;
  footballPlayers: IFootballPlayer[];
}

// const history = createBrowserHistory();
const credentialsMode: RequestCredentials = "include";

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      signedIn: false,
      checkedLogin: false,
      player: {} as IPlayer,
      footballPlayers: []
    };
  }

  render() {
    if (this.state.checkedLogin) {
      const currMyTeam = <MyTeam player={this.state.player} />;
      return (
        <div className="App">
          <Router>
            <NavigationBar isLoggedIn={this.state.signedIn} player={this.state.player} signOut={() => this.signOut()} />
            {/* </nav> */}
            <div id="content">
              {/* <div className="container-fluid"> */}
                {/* <League /> */}
                <Switch>
                  <Route path="/SignIn">
                    <SignIn isLoggedIn={this.state.signedIn} signIn={(username: string, password: string) => this.signIn(username, password)} />
                  </Route>

                  <Route path="/AllPlayers"><AllPlayers footballPlayers={this.state.footballPlayers} /></Route>
                  <Route path="/SignedOut"><SignedOut /></Route>
                  
                  <PrivateRoute isLoggedIn={this.state.signedIn} path="/MyTeam" element={<MyTeam player={this.state.player} />} />

                  {/* <PrivateRoute isLoggedIn={this.state.signedIn} path="/League" element={<League />} /> */}
                  {/* <PrivateRoute isLoggedIn={this.state.signedIn} path="/" element={<Home />} /> */}
                  <Route path="/">
                    <Home />
                  </Route>
                  {/* <Route component={SignIn} /> */}
                </Switch>
              </div>
            {/* </div> */}
          </Router>
        </div>
      );
    }
    else {
      return (
        <div className="App">
          <Router>
            <NavigationBar isLoggedIn={this.state.signedIn} signOut={() => this.signOut()} />
            {/* </nav> */}
            <div id="content">
              <div className="container">

              </div>
            </div>
          </Router>
        </div>
      );
    }

  }

  //un: c6730733@urhen.com
  //pw: W24S0c3x5npE

  public componentDidMount() {
    this.initializeApp()
  }

  private initializeApp(): void {
    // this.checkIfLoggedIn()
    //   .then((loggedInCheck) => {
    //     this.setState({
    //       signedIn: loggedInCheck,
    //       checkedLogin: true
    //     });
    //   })
    //   .then(() => this.getUserInfo())
    //   .then((player) => {
    //     console.log(player);
    //     this.setState({
    //       player: player
    //     }, () => console.log(this.state))
    //   })
    //   .then(() => this.getFootballPlayers())
    //   .then((result) => {
    //     this.setState({
    //       footballPlayers: result
    //     }, () => console.log(this.state));
    //   });

    this.unauthorizedActions()
    .then(() => this.loginActions());
  }

  private loginActions(): Promise<any> {
    return this.checkIfLoggedIn()
    .then((loggedInCheck) => {
      this.setState({
        signedIn: loggedInCheck,
        checkedLogin: true
      });
    })
    .then(() => this.getUserInfo())
    .then((player) => {
      console.log(player);
      this.setState({
        player: player
      })
    });
  }

  private async unauthorizedActions(): Promise<any> {
    let playersResult = await this.getFootballPlayers();
    let understat = await this.getUnderstatData();

    let footballPlayers = this.matchFplAndUnderstat(playersResult, understat);


    this.setState({
        footballPlayers: footballPlayers
      });

    // return this.setState({
    //   footballPlayers: playersResult
    // });

    // return this.getFootballPlayers()
    // .then((playersResult) => {
    //   let understat = await this.getUnderstatData();

    //   this.setState({
    //     footballPlayers: playersResult
    //   });
    // }
}

  private matchFplAndUnderstat(fplPlayers: IFootballPlayer[], understatPlayers: any[]) {
    let matches: IUnderstat[] = [];
    let players: IFootballPlayer[] = [];

    fplPlayers.forEach(fplPlayer => {
      // console.log(fplPlayer.first_name + ' ' + fplPlayer.second_name);
      let match = understatPlayers.filter(understatPlayer => understatPlayer.player_name === (fplPlayer.first_name + ' ' + fplPlayer.second_name));  
      if(match[0]){
        fplPlayer.understat = match[0];
      }

      players.push(fplPlayer);
    });

    return(players);
  }

  private signOut(): any {
    this.setState({
      signedIn: false
      // checkedLogin: false
    });

    document.cookie = `csrftoken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `pl_profile=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  //Get data about football players
  private getFootballPlayers(): Promise<any> {
    //Image URL: http://platform-static-files.s3.amazonaws.com/premierleague/photos/players/110x140/p + player.photo (med .png i stedet for .jpg)
    let endpoint = `${this.props.baseEndpointUrl}/bootstrap-static/`;

    let p = new Promise((resolve, reject) => {
      fetch(endpoint, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        credentials: credentialsMode
      })
        .then((response: any) => {
          return response.json();
        })
        .then(result => {
          resolve(result.elements);
        })
    })

    return p;
  }

  private getUnderstatData(): Promise<any> {
    let endpoint = `https://fpl.sagholen.org/understat/getPlayersData.php`;

    let p = new Promise((resolve, reject) => {
       fetch(endpoint, {
        method: 'GET',
        headers: {
            'Accept':  'text/html'
        }
      })
        .then((response) => {
            return response.text();
        })
        .then((result: string) => {
            let playersData = JSON.parse(this.decodeEscapeSequence(result));
            resolve(playersData);
        })
    });
    
    return p;
}

  //Methods for sign in
  private getUserInfo(): Promise<any> {
    let endpoint = `${this.props.baseEndpointUrl}/me/`;

    let p = new Promise((resolve, reject) => {
      fetch(endpoint, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        credentials: credentialsMode
      })
        .then((response: any) => {
          return response.json();
        })
        .then(result => {
          if (result.player === null) {
            reject('Could not get team');
          }
          else {
            resolve(result.player);
          }
        });
    });

    return p;
  }

  private checkIfLoggedIn(): Promise<any> {
    let endpoint = `${this.props.baseEndpointUrl}/me/`;

    let p = new Promise((resolve, reject) => {
      fetch(endpoint, {
        method: "GET",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        credentials: credentialsMode
      })
        .then((response: any) => {
          return response.json();
        })
        .then(result => {
          if (result.player === null) {
            resolve(false);
          }
          else {
            resolve(true);
          }
        });
    });

    return p;
  }

  private signIn(username: string, password: string): void {
    this.getToken(username, password)
      .then((result) => {
        console.log('Sign in successful');
        document.cookie = `csrftoken=${result.token}`;
        document.cookie = `pl_profile=${result.pl_profile}`;
        this.setState({
          signedIn: true
        });
      })
      .catch(() => {
        console.log('Sign in failed');
        this.setState({
          signedIn: false
        })
      })
      .then(() => this.initializeApp());
  }

  private getToken(username: string, password: string): Promise<any> {
    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);


    console.log(formData);

    let p = new Promise((resolve, reject) => {
      fetch("https://fpl.sagholen.org/getCookie/fplGetCookieWithCreds.php", {
        method: "POST",
        headers: {
          "Accept": "application/json",
        },
        body: formData
      })
        .then(response => {
          return response.json();
        })
        .then(result => {
          if (result.token != null && result.pl_profile != null) {
            resolve(result);
          }
          else {
            reject('Not signed in');
          }
        });
    });

    return p;
  }

  private decodeEscapeSequence(string: string) {
    return string.replace(/\\x([0-9A-Fa-f]{2})/g, function() {
        return String.fromCharCode(parseInt(arguments[1], 16));
    });
}

}

export default App;





// const App: React.FC = () => {
//   return (
//     <div className="App">
//       {/* <div className="row">
//         <header id="header" className="col-12 col-xl-12">
//           <div className="container">
//           <h1 className="logo col-3">Stats</h1>
//           <nav className="logo col-9">
//             Meny
//           </nav>
//           </div>
//         </header>
//       </div> */}
//       {/* <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
//         <h1 className="navbar-brand">Stats</h1> */}
//         <NavigationBar />
//       {/* </nav> */}
//       <div className="container">
//         <div>
//           <League />
//         </div>

//       </div>
//     </div>
//   );
// }

// export default App;
