import * as React from 'react';
import { IPlayer } from '../types/IPlayer';

export interface MyTeamProps {
    player: IPlayer;
}
 
export interface MyTeamState {
    
}

const baseEndpointUrl: string = "https://fpl.sagholen.org/api";
const credentialsMode: RequestCredentials = "include";

class MyTeam extends React.Component<MyTeamProps, MyTeamState> {
    state = { }
    render() { 
        return ( 
            <div className="container">
                <p>Coming soon</p>

            </div>
         );
    }

    public componentDidMount(): void{
        this.getTeam(this.props.player.entry);
    }

    private getTeam(entryId: number) {
        let endpoint = `${baseEndpointUrl}/my-team/${entryId}`;
        console.log(endpoint);
        return fetch(endpoint, {
        method: "GET",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        credentials: credentialsMode
        })
        .then((response: any) => {
            return response.json();
        })
        .then(result => {
            console.log(result);
            return result;
        });
    }
}
 
export default MyTeam;