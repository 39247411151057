import * as React from 'react';
import { Component } from 'react';

import { Link } from 'react-router-dom';
import { IPlayer } from '../types/IPlayer';

// import '../../node_modules/bootstrap/scss/bootstrap.scss';

export interface NavigationBarProps {
    isLoggedIn: Boolean;
    player?: IPlayer;
    signOut: Function;
}

export interface NavigationBarState {

}

class NavigationBar extends React.Component<NavigationBarProps, NavigationBarState> {
    constructor(props: NavigationBarProps) {
        super(props);
        this.state = {};
    }
    render() {
        if (this.props.isLoggedIn && this.props.player) {
            return (
                <React.Fragment>
                    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                        <div className="container">
                            <Link to="/" ><h1 className="navbar-brand">FPL Stats</h1></Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                {/* <ul className="navbar-nav mr-auto"> */}
                                <ul className="navbar-nav ml-auto">
                                    {/* <li className="nav-item active"> */}
                                    <li className="nav-item">
                                        {/* <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a> */}
                                        <Link to="/" className="nav-link">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/AllPlayers" className="nav-link">All Players</Link>
                                    </li>
                                    <li className="nav-item">
                                        {/* <a className="nav-link" href="/League">League</a> */}
                                        <Link to="/MyTeam" className="nav-link">My Team</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to="/League" className="nav-link">League</Link>
                                    </li> */}
                                </ul>
                                <button className="btn btn-md btn-danger" onClick={() => this.handleSignout()}>Sign out {this.props.player.first_name}</button>
                            </div>
                        </div>
                    </nav>
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                        <div className="container">
                            <Link to="/" ><h1 className="navbar-brand">FPL Stats</h1></Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                {/* <ul className="navbar-nav mr-auto"> */}
                                <ul className="navbar-nav ml-auto">
                                    {/* <li className="nav-item active"> */}
                                    <li className="nav-item">
                                        {/* <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a> */}
                                        <Link to="/" className="nav-link">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/AllPlayers" className="nav-link">All Players</Link>
                                    </li>
                                    <li className="nav-item">
                                        {/* <a className="nav-link" href="/League">League</a> */}
                                        <Link to="/MyTeam" className="nav-link">My Team</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to="/League" className="nav-link">League</Link>
                                    </li> */}
                                </ul>
                                <Link to="/SignIn"><button className="btn btn-md btn-primary">Sign in</button></Link>
                            </div>
                        </div>
                    </nav>
                </React.Fragment>
            );
        }
        // else {
        //     return (
        //         <React.Fragment>
        //             <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        //                 <div className="container">
        //                     <h1 className="navbar-brand">FPL Stats</h1>
        //                     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        //                         <span className="navbar-toggler-icon"></span>
        //                     </button>
        //                 </div>
        //             </nav>
        //         </React.Fragment>
        //     );
        // }

    }

    private handleSignout(): void {
        this.props.signOut();
    }
}

export default NavigationBar;