import * as React from 'react';
import { Link } from 'react-router-dom';

export interface SignedOutProps {

}

export interface SignedOutState {

}

class SignedOut extends React.Component<SignedOutProps, SignedOutState> {
    state = {}
    render() {
        return (
            <div>
                <h1>Signed out</h1>
                <Link to="/">Go to frontpage</Link>
            </div>
        );
    }
}

export default SignedOut;