import * as React from 'react';
import { IFootballPlayer } from '../types/IFootballPlayer';
import { TextField, Checkbox } from '@material-ui/core';

export interface AllPlayersProps {
    footballPlayers: IFootballPlayer[];
}

export interface AllPlayersState {
    filteredItems: IFootballPlayer[];
    inputValue: string;
    positionFilter: number[];
}

class AllPlayers extends React.Component<AllPlayersProps, AllPlayersState> {
    constructor(props: AllPlayersProps) {
        super(props);
        this.state = {
            filteredItems: this.props.footballPlayers,
            inputValue: '',
            positionFilter: [1,2,3,4]
        };
    }
    render() {
        this.props.footballPlayers.sort((a, b) => (a.total_points > b.total_points) ? 1 : -1);
        this.props.footballPlayers.reverse();
        //Image URL: http://platform-static-files.s3.amazonaws.com/premierleague/photos/players/110x140/p + player.photo (med .png i stedet for .jpg)

        // http://platform-static-files.s3.amazonaws.com/premierleague/photos/players/110x140/p${footballPlayer.photo.substring(0, footballPlayer.photo.indexOf('.'))}.png

        // let s = this.props.footballPlayers[0].photo;
        // // s = s.substring(0, s.indexOf('.'));
        // console.log(s);
        // let currPlayer = this.props.footballPlayers[10];
        // console.log(currPlayer.first_name);

        // const tableBody = this.props.footballPlayers.map((footballPlayer: IFootballPlayer, i) => {
        const tableBody = this.state.filteredItems.map((footballPlayer: IFootballPlayer, i) => {
            let photoUrl = footballPlayer.photo;
            photoUrl = photoUrl.substring(0, photoUrl.indexOf('.'));

            //Med armer
            photoUrl = 'http://platform-static-files.s3.amazonaws.com/premierleague/photos/players/110x140/p' + photoUrl + '.png';

            //Sjekk om understat eksisterer
            // let xGoals: string | number = `<span>Not available<span>`;
            let xGoals: string | number = "Not available";
            let xAssists: string | number = "Not available";
            
            let xGoalsClass: string = "text-danger"
            let xAssistsClass: string = "text-danger"
            

            if(footballPlayer.understat){
                xGoals = Math.round(Number(footballPlayer.understat.xG) * 100) / 100;
                xAssists = Math.round(Number(footballPlayer.understat.xA) * 100) / 100;

                xGoalsClass = footballPlayer.goals_scored > xGoals ? "text-success" : "text-danger";
                xAssistsClass = footballPlayer.assists > xAssists ? "text-success" : "text-danger";
            }

            return (
                <tr key={footballPlayer.code}>
                    <td><img src={photoUrl} className="playerImage" alt={footballPlayer.code.toString()} /></td>
                    <td>{footballPlayer.first_name} {footballPlayer.second_name}</td>
                    <td>{footballPlayer.total_points}</td>
                    <td>{footballPlayer.now_cost / 10}</td>
                    <td>{footballPlayer.goals_scored}</td>
                    {/* <td>{xGoals} ${this.getXStyle(footballPlayer.goals_scored,xGoals)} </td><span className={xGoalsClass}>{(typeof(xGoals) === 'number' && footballPlayer.goals_scored > xGoals) ? '(+' : '('}{typeof(xGoals) === 'number' ? Math.round((footballPlayer.goals_scored - xGoals) * 100) / 100 : null }</span></td> */}
                    <td>{xGoals}<span dangerouslySetInnerHTML={{__html: this.getXStyle(footballPlayer.goals_scored,xGoals)}}></span></td>
                    <td>{footballPlayer.assists}</td>
                    {/* <td>{xAssists} <span className={xAssistsClass}>({(typeof(xAssists) === 'number' && footballPlayer.assists > xAssists) ? '+' : ''}{typeof(xAssists) === 'number' ? Math.round((footballPlayer.assists - xAssists) * 100) / 100 : null }</span></td> */}
                    <td>{xAssists}<span dangerouslySetInnerHTML={{__html: this.getXStyle(footballPlayer.assists,xAssists)}}></span></td>
                    <td>{Math.round(((footballPlayer.total_points / footballPlayer.minutes) * 90) * 100) / 100}</td>
                    <td>{footballPlayer.minutes}</td>
                    <td>{this.getPosition(footballPlayer.element_type)}</td>
                </tr>
            );
        })


        const searchInputProps = {
            autofocus: true,
            variant: 'outlined'
        }

        return (
            <div className="container-fluid">
                <div id="searchInput">
                    <div id="searchField">
                        Search for players: <TextField id="searchPlayers" inputProps={searchInputProps} onChange={(event: any) => this._onFilterStringChanged(event.target.value)} />
                    </div>
                    <div id="searchCheckboxes">
                        <Checkbox defaultChecked onChange={(e: any) => this._onFilterPositionChanged(e.target.value,e.target.checked)} value={1}  /><label>Goalkeepers</label>
                        <Checkbox defaultChecked onChange={(e: any) => this._onFilterPositionChanged(e.target.value,e.target.checked)} value={2}  /><label>Defenders</label>
                        <Checkbox defaultChecked onChange={(e: any) => this._onFilterPositionChanged(e.target.value,e.target.checked)} value={3}  /><label>Midfielders</label>
                        <Checkbox defaultChecked onChange={(e: any) => this._onFilterPositionChanged(e.target.value,e.target.checked)} value={4}  /><label>Attackers</label> 
                    </div>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Image</th>
                            <th scope="col">Name</th>
                            <th scope="col">Total Points</th>
                            <th scope="col">Price</th>
                            <th scope="col">Goals</th>
                            <th scope="col">xGoals</th>
                            <th scope="col">Assists</th>
                            <th scope="col">xAssists</th>
                            <th scope="col">Avg points per 90 min</th>
                            <th scope="col">Minutes played</th>
                            <th scope="col">Position</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableBody}
                    </tbody>
                </table>
            </div>
        );
    }

    private _onFilterStringChanged(text: string): void {
        this.setState({
            inputValue: text.toLowerCase()
        }, () => {
            let filteredItems = this.filterItems(this.props.footballPlayers);
            this.setState({
                filteredItems: filteredItems
            });
        })
    }

    private _onFilterPositionChanged(value: string, checked: boolean): void {
        let positionFilter = this.state.positionFilter;

        if(checked){
            positionFilter.push(Number(value));
        }
        else {
            positionFilter = positionFilter.filter(
              x => (x != Number(value))
            );
        }

        console.log(positionFilter);

        this.setState({
            positionFilter: positionFilter
        }, () => {
            let filteredItems = this.filterItems(this.props.footballPlayers);
            this.setState({
                filteredItems: filteredItems
            });
        })
    }

    private getPosition(elementType: number) {
        switch (elementType) {
            case 1:
                return 'GK'
                break;
        
            case 2:
                return 'D';
                break;

            case 3:
                return 'M';
                break;

            case 4:
                return 'A';
                break;

            default:
                return '';
                break;
        }
    }

    private filterItems(items: IFootballPlayer[]): IFootballPlayer[]{
        let filteredItems: IFootballPlayer[] = items.filter(
        //   x => (x.first_name.toLowerCase().indexOf(this.state.inputValue) > -1 || x.second_name.toLowerCase().indexOf(this.state.inputValue) > -1) && (this.state.positionFilter.includes(x.element_type))
        x => ((x.first_name.toLowerCase() + ' ' + x.second_name.toLowerCase()).indexOf(this.state.inputValue) > -1 && (this.state.positionFilter.includes(x.element_type)))
        );
        return filteredItems;
    }

    private getXStyle(real: number, expected: number | string): string {
        let text = ``;
        if(typeof(expected) === 'number'){
            let xStyle = real > expected ? 'text-success' : 'text-danger';
            let prefix = real > expected ? '+' : '';
            text = ` <span class=${xStyle}>(${prefix + Math.round((real - expected) * 100) / 100 })</span>`;
        }
        
        return text;
    }
}

export default AllPlayers;