import * as React from 'react';

export interface HomeProps {

}

export interface HomeState {

}

class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: HomeProps) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <div className="container">
                Fantasy stats!
            </div>

        );
    }
}

export default Home;